import React from 'react'

import styles from './loader.module.css'
import { ReactComponent as LoaderSvg } from './loader.svg'

export const Loader = () => {
  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <LoaderSvg />
      </div>
    </div>
  )
}
