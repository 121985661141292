import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'

import { Loader } from './loader'
// import CookieNotification from './components/modals/cookie-notification'
import reportWebVitals from './report-web-vitals'

const Auth = lazy(() => import(/* webpackChunkName: "Auth" */ './auth').then(module => ({ default: module.Auth })))
const App = lazy(() => import(/* webpackChunkName: "App" */ './app').then(module => ({ default: module.App })))

const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}

window.addEventListener('resize', appHeight)
appHeight()

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <Auth />
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
